import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { getCustomFields, parseBoolean, retrieveCertification } from '../../ts/Common';
import { encode, trim } from 'url-safe-base64';
import { Grid, Typography } from '@mui/material'
import CenterControl from './Details/CenterControl';
import ScanHistory from './History/ScanHistory';
import ScanHistoryMobile from './History/ScanHistoryMobile';
import ScanHistoryDrawer from './History/ScanHistoryDrawer';
import ErrorModal from './components/machineButtonComponents/ErrorModal';
import Inputs from './Inputs/Inputs';
import { Cargo } from '../../ts/Cargo';
import { checkMachineResponse } from '../../ts/MachineResponseParser';
import _ from 'lodash'
import { NotificationCategory, TextEventProps } from '../../ts/MachineEvent';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import ReportIcon from '@mui/icons-material/Report';
const errorSound = new Audio("https://cargospectre.blob.core.windows.net/assets/sounds/error.mp3");
const successSound = new Audio("https://cargospectre.blob.core.windows.net/assets/sounds/success.mp3");

export default function Control(props: any) {
    const { machine, showMachineSettings, setShowMachineSettings, showCalibration, setShowCalibration, showSupport, setShowSupport, showCertification, setShowCertification, onlineMachines, setSelectedMachine, machineEvents, setMachineEvents } = props;
    const navigate = useNavigate();
    const [customFields, setCustomFields] = useState<JSON[]>([]);
    const [customFieldDropDownValues, setCustomFieldDropDownValues] = useState<JSON[]>([]);
    const [customFieldSubmitValues, setCustomFieldSubmitValues] = useState<any>({});
    const [customFieldSubmitValuesEmpty, setCustomFieldSubmitValuesEmpty] = useState<Object>({});
    const [clearCustomFieldsAfterUpload, setClearCustomFieldsAfterUpload] = useState<boolean>(false);
    const [barcodeValue, setBarcodeValue] = useState<string>("");
    const [cargoResponses, setCargoResponses] = useState<Cargo[]>([]);
    const [machineCommand, setMachineCommand] = useState<string>("dimension");
    const [machineActive, setMachineActive] = useState<boolean>(false);
    const [customFieldsLoaded, setCustomFieldsLoaded] = useState<boolean>(false);
    const [settingsLoaded, setSettingsLoaded] = useState<boolean>(false);
    const [disableScanButton, setDisableScanButton] = useState<boolean>(false);
    const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
    const [errorModalText, setErrorModalText] = useState<string>("");
    const [selectedScanIndex, setSelectedScanIndex] = useState(0);
    const [settings, setSettings] = useState<any>({});
    const [openScanDetails, setOpenScanDetails] = useState<boolean>(false);
    const [scanButtonPressed, setScanButtonPressed] = useState<boolean>(false);
    const [customFieldsReadyForSubmission, setCustomFieldsReadyForSubmission] = useState<boolean>(false)
    const [MachineSwapWithScanner, setMachineSwapWithScanner] = useState<boolean>(false);
    
    useEffect(() => {
        setCargoResponses([]);
        machine ? machine.connection.ping()
            .then((response: boolean) => {
                if (!response) {
                    console.log('machine ping failure, redirecting to matchmaking')
                    navigate('/matchmaking')
                } else {
                    getCustomFields()
                        .then((data) => {
                            data.customFieldValues.length > 0 ? setCustomFields(data.customFieldValues) : setCustomFields([]);
                            data.customFieldDropDownValues.length > 0 ? setCustomFieldDropDownValues(data.customFieldDropDownValues) : setCustomFieldDropDownValues([]);
                            setCustomFieldsLoaded(true)
                            return customFields
                        })
                    retrieveCertification(machine)
                        .then((response) => {
                            if (response['Responses']['GetCertification']['code'] === '0') {
                                machine.certification = response['Responses']['GetCertification']
                            }
                        })
                }
            })
            : navigate('/matchmaking')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [machine]);

    useEffect(() => {
        if (settingsLoaded) {
            setClearCustomFieldsAfterUpload(settings.Scanner.ClearCustomFields.value)
            setMachineSwapWithScanner(settings.Scanner.MachineSwapWithScanner.value)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showMachineSettings, machine, settingsLoaded])

    useEffect(() => {
        if (_.get(settings, 'Calibration.Path.value') === "") {
            setDisableScanButton(true);
            setOpenErrorModal(true);
            setErrorModalText("Machine not calibrated - please calibrate to enable scanning")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [settingsLoaded])

    useEffect(() => {
        // Check on scan button press for machine name match
        if (scanButtonPressed && MachineSwapWithScanner && barcodeValue.startsWith('SPECTRE:')) {
            setMachineActive(true);
            const swapCandidate = barcodeValue.split(':')[1];
            if (swapCandidate === machine.machineName) {
                const switchEvent = new TextEventProps();
                switchEvent.icon = ReportIcon;
                switchEvent.subject = 'Ignored attempt to switch to same machine:';
                switchEvent.message = machine.machineName;
                switchEvent.category = NotificationCategory.INFO;
                setMachineEvents([...machineEvents, switchEvent])
                setScanButtonPressed(false);
                setMachineActive(false);
                return;
            }

            const promises = [];
            for (let i = 0; i < onlineMachines.length; i++) {
                if (barcodeValue === 'SPECTRE:' + onlineMachines[i].machineName) {
                    promises.push(onlineMachines[i].connection.ping()
                        .then((response: boolean) => {
                            if (!response) {
                                const switchEvent = new TextEventProps();
                                switchEvent.icon = ReportIcon;
                                switchEvent.subject = 'Failed to switch to:';
                                switchEvent.message = onlineMachines[i].machineName;
                                switchEvent.category = NotificationCategory.WARNING;
                                setMachineEvents([...machineEvents, switchEvent])
                                setScanButtonPressed(false);
                                return false;
                            }
                            else {
                                setSelectedMachine(onlineMachines[i]);
                                setBarcodeValue('');
                                const switchEvent = new TextEventProps();
                                switchEvent.icon = SwapHorizIcon;
                                switchEvent.subject = 'Switched to:';
                                switchEvent.message = onlineMachines[i].machineName;
                                switchEvent.category = NotificationCategory.PRIMARY;
                                setMachineEvents([switchEvent])
                                setScanButtonPressed(false);
                                return true;
                            }
                        })
                        .catch(() => {
                            return false;
                        })
                    );
                }
            }
            Promise.all(promises).then((promiseResults: boolean[]) => {
                if (!promiseResults.includes(true)) {
                    const switchEvent = new TextEventProps();
                    switchEvent.icon = ReportIcon;
                    switchEvent.subject = `Failed to switch to:`;
                    switchEvent.message = swapCandidate;
                    switchEvent.category = NotificationCategory.WARNING;
                    setMachineEvents([...machineEvents, switchEvent])
                    setScanButtonPressed(false);
                }
            });
            setMachineActive(false);
            return;
        }

        // Two checks - one for users with custom fields, and one for users without custom fields. 
        if ((scanButtonPressed === true && customFieldsReadyForSubmission === true) || (scanButtonPressed === true && !customFieldSubmitValues['CustomFields']['Field'].length)) {
            if ('AutoBarcodeDelay' in settings.Scanner) {
                let triggerDelay = settings.Scanner.AutoBarcodeDelay.value;
                if (triggerDelay && !isNaN(triggerDelay) && triggerDelay > 0) {
                    setTimeout(function () {
                        machineScan(`${machineCommand}`)
                    }, Number(triggerDelay) * 1000)
                } else {
                    machineScan(`${machineCommand}`)
                }
            } else {
                machineScan(`${machineCommand}`)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scanButtonPressed, customFieldsReadyForSubmission])

    const handleCloseErrorModal = () => setOpenErrorModal(false);

    const openDetailsModal = function () {
        console.log('Scan Details Modal Disabled on Medium view')
    }

    const checkCustomFieldsReady = function () {
        const checker = (arr: any) => arr.every((element: any) => element['Ready'] === true);
        if (customFieldSubmitValues['CustomFields']['Field'].length) {
            // checks that every custom field has it's "Ready" status set to true, if true, setCustomFIeldsReadyForSubmission to True
            if (checker(customFieldSubmitValues['CustomFields']['Field'])) {
                setCustomFieldsReadyForSubmission(true)
            } else {
                setCustomFieldsReadyForSubmission(false);
            }
        } else {
            setCustomFieldsReadyForSubmission(true);
        }
    }

    // runs through custom fields and sets Auto Increment field ready state to false
    const resetCustomFieldsReady = function () {
        let customFieldsSubmitValuesCopy = { ...customFieldSubmitValues };
        if (customFieldsSubmitValuesCopy['CustomFields']['Field'].length) {
            customFieldsSubmitValuesCopy['CustomFields']['Field'].forEach((field: any) => {
                field['FieldType'] === 4 ? field['Ready'] = false : field['Ready'] = true
            });
            setCustomFieldSubmitValues(customFieldsSubmitValuesCopy);
        }
    }

    

    async function machineScan(cmd: string) {
        if (commands[cmd]()['Requests']['Dimension']) {
            for (let i = 0; i < customFieldSubmitValues['CustomFields']['Field'].length; i++) {
                if (customFieldSubmitValues['CustomFields']['Field'][i]['Required'] && !customFieldSubmitValues['CustomFields']['Field'][i]['Value']) {
                    alert('A required custom field was not filled in. Your organization has a custom field that was marked as required on every scan. \n\nPlease check for any fields marked with a red asterisk(*) and make sure they are filled in prior to pressing the Scan button.')
                    return;
                }
            }
        }
        setMachineActive(true);

        return await machine.connection.post(commands[cmd]())
            .then((response: Response) => {
                setBarcodeValue("")
                setMachineActive(false);
                const shouldPlaySound = parseBoolean(settings.Scanner.PlaySoundAfterScanning.value);
                // sets the selected scan back to 0 index on each new scan
                setScanButtonPressed(false)
                setCustomFieldsReadyForSubmission(false)
                setSelectedScanIndex(0)
                // handle Dimension responses
                if (checkMachineResponse(response, 'Dimension')) {
                    console.log(response)
                    const cargo = new Cargo(machine.certification, customFieldSubmitValues);
                    cargo.parseResponse(response, cmd)
                    try {
                        if (shouldPlaySound && (cargo.uploaded === false || cargo.dimensionCode === "error" || cargo.exported === false)) {
                            errorSound.play();
                        } else if (shouldPlaySound) {
                            successSound.play();
                        }
                    } catch {
                        console.warn('Error playing sound');
                    }
                    setCargoResponses([cargo, ...cargoResponses])
                } else {
                    cargoResponses[0].parseResponse(response, cmd)
                }
                if (checkMachineResponse(response, 'Upload')) {
                    clearCustomFieldsAfterUpload ? setCustomFieldSubmitValues(customFieldSubmitValuesEmpty) : resetCustomFieldsReady();
                } else {
                    resetCustomFieldsReady();
                }
            })
            .catch((err: any) => {
                try {
                    errorSound.play();
                } catch { 
                    console.warn('error playing sound')
                }
                setBarcodeValue("")
                setMachineActive(false);
                setScanButtonPressed(false);
                setCustomFieldsReadyForSubmission(false)
                setErrorModalText('Communication timeout, please check machine is online and try again');
                setOpenErrorModal(true);
                checkCustomFieldsReady();
                resetCustomFieldsReady();
                console.log('communication error', err)
            })
    };

    // Form submission helpers
    const encodeCustomFields = function () {
        if (customFields.length === 0) {
            return ""
        }
        let encodedFields = btoa(JSON.stringify(customFieldSubmitValues));
        encodedFields = encode(encodedFields);
        return trim(encodedFields);
    }

    const commands: any = {
        dimension: () => {
            return {
                "Requests": {
                    "Dimension": {
                        "Barcode": barcodeValue,
                        "CustomFields": encodeCustomFields()
                    },
                    "Snapshot": {},
                }
            }
        },
        upload: () => {
            return {
                "Requests": {
                    "Upload": {
                        "AutoShare": settingsLoaded && 'AutoShareOnUpload' in settings.Scanner ? settings.Scanner.AutoShareOnUpload.value : false
                    }
                }
            }
        },
        export: () => {
            return {
                "Requests": {
                    "Export": {
                        "Endpoint": machine.settings.Export.Url.value,
                        "Format": machine.settings.Export.Format.value.toLowerCase(),
                        "Method": machine.settings.Export.Method.value.toLowerCase(),
                        "AuthHeader": machine.settings.Export.AuthHeader.value,
                        "FtpUsername": machine.settings.Export.Username.value,
                        "FtpPassword": machine.settings.Export.Password.value,
                    }
                }
            }
        },
        dimensionAndUpload: () => {
            return {
                "Requests": {
                    "Dimension": {
                        "Barcode": barcodeValue,
                        "CustomFields": encodeCustomFields()
                    },
                    "Snapshot": {},
                    "Upload": {
                        "AutoShare": settingsLoaded && 'AutoShareOnUpload' in settings.Scanner ? settings.Scanner.AutoShareOnUpload.value : false
                    }
                }
            }
        },
        uploadAndExport: () => {
            return {
                "Requests": {
                    "Upload": {
                        "AutoShare": settingsLoaded && 'AutoShareOnUpload' in settings.Scanner ? settings.Scanner.AutoShareOnUpload.value : false
                    },
                    "Export": {
                        "Endpoint": machine.settings.Export.Url.value,
                        "Format": machine.settings.Export.Format.value.toLowerCase(),
                        "Method": machine.settings.Export.Method.value.toLowerCase(),
                        "AuthHeader": machine.settings.Export.AuthHeader.value,
                        "FtpUsername": machine.settings.Export.Username.value,
                        "FtpPassword": machine.settings.Export.Password.value,
                    }
                }
            }
        },
        scanUploadAndExport: () => {
            return {
                "Requests": {
                    "Dimension": {
                        "Barcode": barcodeValue,
                        "CustomFields": encodeCustomFields()
                    },
                    "Snapshot": {},
                    "Upload": {
                        "AutoShare": settingsLoaded && 'AutoShareOnUpload' in settings.Scanner ? settings.Scanner.AutoShareOnUpload.value : false
                    },
                    "Export": {
                        "Endpoint": machine.settings.Export.Url.value,
                        "Format": machine.settings.Export.Format.value.toLowerCase(),
                        "Method": machine.settings.Export.Method.value.toLowerCase(),
                        "AuthHeader": machine.settings.Export.AuthHeader.value,
                        "FtpUsername": machine.settings.Export.Username.value,
                        "FtpPassword": machine.settings.Export.Password.value,
                    }
                }
            }
        }
    }

    const styles = {
        scanHistory: {
            maxHeight: '80vh',
            overflow: "auto",
        },
        customFields: {
            overflow: "auto",
            maxHeight: {
                xs: '50vh',
                sm: '60vh',
                md: '75vh'
            }
        },
        centerControl: {
            maxHeight: '100vh',
            overflow: 'auto',
        }
    }

    return (
        <div>
            <link rel="icon" href="data:,"></link>
            <Grid container direction="row" style={{
                minWidth: "100%",
                height: "100vh",
            }}>
                <Grid container item direction="column" justifyContent="space-between" md={6} sm={6} lg={3} xs={12}
                    sx={{
                        borderRight: '2px solid',
                        borderColor: '#D3D3DE',
                    }}>
                    <Inputs customFields={customFields} customFieldDropDownValues={customFieldDropDownValues} customFieldSubmitValues={customFieldSubmitValues} setCustomFieldSubmitValues={setCustomFieldSubmitValues}
                        setCustomFieldSubmitValuesEmpty={setCustomFieldSubmitValuesEmpty} customFieldsLoaded={customFieldsLoaded} barcodeValue={barcodeValue} setBarcodeValue={setBarcodeValue} machineActive={machineActive}
                        settings={settings} showMachineSettings={showMachineSettings} settingsLoaded={settingsLoaded} machineCommand={machineCommand} setMachineCommand={setMachineCommand} machineScan={machineScan}
                        scanButtonPressed={scanButtonPressed} setScanButtonPressed={setScanButtonPressed} disableScanButton={disableScanButton} setDisableScanButton={setDisableScanButton} cargoResponses={cargoResponses}
                        checkCustomFieldsReady={checkCustomFieldsReady} machine={machine}></Inputs>
                </Grid>
                <ErrorModal openErrorModal={openErrorModal} handleCloseErrorModal={handleCloseErrorModal} errorModalText={errorModalText}></ErrorModal>
                <Grid item md={6} sm={6} xs={12} sx={styles.centerControl} display={{ xs: 'none', lg: 'block' }}>
                    <CenterControl showMachineSettings={showMachineSettings}
                        setShowMachineSettings={setShowMachineSettings} machine={machine} showCalibration={showCalibration}
                        setShowCalibration={setShowCalibration} setClearCustomFieldsAfterUpload={setClearCustomFieldsAfterUpload} setDisableScanButton={setDisableScanButton}
                        setOpenErrorModal={setOpenErrorModal} setErrorModalText={setErrorModalText} cargoResponse={cargoResponses[selectedScanIndex]} selectedScanIndex={selectedScanIndex}
                        settings={settings} setSettings={setSettings} setSettingsLoaded={setSettingsLoaded} showSupport={showSupport} setShowSupport={setShowSupport}
                        showCertification={showCertification} setShowCertification={setShowCertification} openScanDetails={openScanDetails} setOpenScanDetails={setOpenScanDetails}></CenterControl>
                </Grid>
                <Grid item lg={3} style={{ paddingTop: 67 }} sx={{ backgroundColor: "#F5F5F8", display: { xs: 'none', sm: 'none', md: 'none', lg: 'block' } }} >
                    <Grid item lg={12} style={{ backgroundColor: '#EAEAF0' }} sx={{
                        borderBottom: '2px solid',
                        borderColor: '#D3D3DE',
                    }}>
                        <Typography variant="h6" color="#4B4E65">Event History</Typography>
                    </Grid>
                    <Grid sx={styles.scanHistory}>
                        <ScanHistory selectedScanIndex={selectedScanIndex} setSelectedScanIndex={setSelectedScanIndex} cargoResponses={cargoResponses}
                            setOpenScanDetails={setOpenScanDetails} openDetailsModal={openDetailsModal} machine={machine} machineEvents={machineEvents}
                        />
                    </Grid>
                </Grid>
                <Grid item md={6} sm={6} style={{ paddingTop: 67 }} sx={{ backgroundColor: "#F5F5F8", display: { xs: 'none', sm: 'block', md: 'block', lg: 'none' } }} >
                    <Grid item md={12} style={{ backgroundColor: '#EAEAF0' }} sx={{
                        borderBottom: '2px solid',
                        borderColor: '#D3D3DE',
                    }}>
                        <Typography variant="h6" color="#4B4E65">Event History</Typography>
                    </Grid>
                    <Grid sx={styles.scanHistory}>
                        <ScanHistoryMobile selectedScanIndex={selectedScanIndex} setSelectedScanIndex={setSelectedScanIndex} cargoResponses={cargoResponses}
                            setOpenScanDetails={setOpenScanDetails} openDetailsModal={() => setOpenScanDetails(true)} machine={machine} machineEvents={machineEvents}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid display={{ xs: 'block', sm: 'none' }}>
                <ScanHistoryDrawer selectedScanIndex={selectedScanIndex} setSelectedScanIndex={setSelectedScanIndex} cargoResponses={cargoResponses}
                    setOpenScanDetails={setOpenScanDetails} machine={machine} machineEvents={machineEvents}
                ></ScanHistoryDrawer>
            </Grid>
        </div>
    )
}